$(function() {

    new SimpleBar($('.playlist__content')[0]);
    
    $('.header__menu-toggle').click(function(){
        $('header').toggleClass('active');
        return false;
    });

    $('#datetime_1').Zebra_DatePicker({
        default_position: 'below',
        direction: true,
        enabled_minutes: [0,10,20,30,40,50],
        format: 'Y-m-d H:i A',
        onOpen: function() {
            $('.Zebra_DatePicker:not(.dp_hidden)').find('.dp_timepicker').css('height', $('.Zebra_DatePicker:not(.dp_hidden)').find('.dp_daypicker').height());
        }
    });

    $('.digit-control__btn').click(function(){
        var count = parseInt($('.digit-control__input').val());
        if($(this).attr('data-digit-control') === 'less') {
            if(count > 1) {
                count--;
                $('.digit-control__input').val(count);
                $('#gift_recipients .form__fieldset:last-child').remove();
            }
        } else {
            count++;
            $('.digit-control__input').val(count);
            $('#gift_recipients').append('<div class="form__fieldset"><label class="fs-4 fw-600 mb-2">Gift '+count+': Recipients Info</label><div class="row mb-4 pt-1"><div class="col-md-6"><div class="form-group"><label for="name_'+count+'">Name</label><input type="text" class="form-control" name="name" id="name_'+count+'" required></div></div><div class="col-md-6"><div class="form-group"><label for="email_'+count+'">Email Address</label><input type="email" class="form-control" name="email" id="email_'+count+'" required></div></div></div><div class="d-flex align-items-center justify-content-between mb-3"><div class="left"><label class="fs-4 mb-0 text-muted text-normal">Delivery Time & Date</label></div><div class="right"><input type="radio" class="btn-check" name="delivery_type_'+count+'" id="schedule_'+count+'" value="schedule" autocomplete="off" required><label class="btn btn-outline-light" for="schedule_'+count+'">Schedule</label><input type="radio" class="btn-check" name="delivery_type_'+count+'" id="now_'+count+'" value="now" autocomplete="off" required><label class="btn btn-outline-light ms-3" for="now_'+count+'">Now</label></div></div><div class="form-group js-datetime-wrapper" style="display:none"><label for="datetime_'+count+'">Date & Time</label><input type="text" class="form-control form-control__date" id="datetime_'+count+'" placeholder="Select a Date & Time"></div></div>');
            $('#datetime_'+count).Zebra_DatePicker({
                default_position: 'below',
                direction: true,
                enabled_minutes: [0,10,20,30,40,50],
                format: 'Y-m-d H:i A',
                onOpen: function() {
                    $('.Zebra_DatePicker:not(.dp_hidden)').find('.dp_timepicker').css('height', $('.Zebra_DatePicker:not(.dp_hidden)').find('.dp_daypicker').height());
                }
            });
        }
    });

    $(document).on('change', '.btn-check', function(){
        if($(this).val() === 'schedule') {
            $(this).parents('.form__fieldset').find('.js-datetime-wrapper').show();
        } else {
            $(this).parents('.form__fieldset').find('.js-datetime-wrapper').hide();
        }
    });

    var viatorForm = $('#viator_register_form');

    if(viatorForm.length > 0 || $('#register_form').length > 0) {
        const emailInput = document.getElementById('email');
        const passInput = document.getElementById('password');
        const passConfirmInput = document.getElementById('password_confirmation');
        const validateEmail = (email) => {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emailPattern.test(email);
        };

        const validatePassword = (password, password_confirmation) => {
            const passwordInput = $(password).val();
            const confirmPasswordInput = $(password_confirmation).val();

            if (passwordInput !== confirmPasswordInput) {
                $('#password_msg_errors').show().find('i').text('Looks like the passwords you entered do not match');
            } else {
                $('#password_msg_errors').hide();
            }
        };

        // Add an event listener to validate the email input on input change
        emailInput.addEventListener('input', (event) => {
            const email = event.target.value;
            const isValid = validateEmail(email);

            // Update the input's validity state to show/hide the browser's default validation message
            event.target.setCustomValidity(isValid ? '' : 'Please enter a valid email address');
        });

        passInput.addEventListener('blur', (event) => {
            const password = event.target.value;

            if (password.length < 8) {
                $('#password_msg_errors').show().find('i').text('The password must be at least 8 characters.');
            }
        });

        passConfirmInput.addEventListener('blur', (event) => {
                const isValid = validatePassword('#password', '#password_confirmation');
            });

            viatorForm.on('submit', function (event) {
            const passwordInput = $("#password").val();
            const confirmPasswordInput = $("#password_confirmation").val();

            if (passwordInput !== confirmPasswordInput) {
                $('#password_msg_errors').show().find('i').text('Looks like the passwords you entered do not match');
                event.preventDefault();
            }
        });

        $(window).on('scroll', function() {
            var scrollTop = $(window).scrollTop();
            var formOffsetTop = viatorForm.offset().top;
            var formHeight = viatorForm.height();

            // Check if the form is not visible
            if (scrollTop > formOffsetTop + formHeight) {
                // Add a class to the body when the form is not visible
                viatorForm.find('.scroll-notice').hide();
            } else {
                // Remove the class when the form is visible
                viatorForm.find('.scroll-notice').show();
            }
        });
    }

    $('.header__main-menu-item--dropdown').hover(
        function() {
            $('header').addClass('header--expanded');
        },
        function() {
            $('header').removeClass('header--expanded');
        },
    );

    var $downloadButton = $('a[data-bs-target="#downloadModal"]');
    function openAppLink() {
        var isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        var isAndroid = /Android/.test(navigator.userAgent);
        $downloadButton.removeClass('modalOpened');

        if (isiOS) {
            // Open the link for iOS devices
            window.open('https://apps.apple.com/us/app/id1300494609', '_blank');
            $downloadButton.addClass('modalOpened');
        } else if (isAndroid) {
            // Open the link for Android devices
            window.open('https://play.google.com/store/apps/details?id=autio.audio.travel.guide.stories', '_blank');
            $downloadButton.addClass('modalOpened');   
        }
    }
  
    // Add a click event handler to the button
    $('a[data-bs-target="#downloadModal"]').click(openAppLink);

    var downloadModal = document.getElementById('downloadModal');
    var myModal = new bootstrap.Modal(document.getElementById('downloadModal'));
    downloadModal.addEventListener('shown.bs.modal', function () {
        if($downloadButton.hasClass('modalOpened')){
            myModal.hide()
        }
    })

});